import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase';

function FormDataPage() {
    const [formData, setFormData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedReason, setSelectedReason] = useState('all');
    const [sortOrder, setSortOrder] = useState('desc');
    const [uniqueReasons, setUniqueReasons] = useState([]);

    const fetchFormData = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'formdata'));
            const data = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setFormData(data);
        } catch (error) {
            console.error('Error fetching form data:', error);
        }
    };

    useEffect(() => {
        fetchFormData();
    }, []);

    useEffect(() => {
        // Extract unique reasons
        const reasons = [...new Set(formData.map(item => item.reason))];
        setUniqueReasons(reasons);

        // Apply filters and sorting
        let filtered = [...formData];
        
        if (selectedReason !== 'all') {
            filtered = filtered.filter(item => item.reason === selectedReason);
        }

        filtered.sort((a, b) => {
            const timeA = a.timestamp.seconds;
            const timeB = b.timestamp.seconds;
            return sortOrder === 'desc' ? timeB - timeA : timeA - timeB;
        });

        setFilteredData(filtered);
    }, [formData, selectedReason, sortOrder]);

    return (
        <div className="container mx-auto px-4 py-8">
            <h2 className="text-3xl font-bold mb-4">Form Data</h2>
            
            <div className="mb-4 flex gap-4 items-center text-black">
                <select 
                    className="border p-2 rounded"
                    value={selectedReason}
                    onChange={(e) => setSelectedReason(e.target.value)}
                >
                    <option value="all">All Reasons</option>
                    {uniqueReasons.map(reason => (
                        <option key={reason} value={reason}>{reason}</option>
                    ))}
                </select>

                <button 
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={() => setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')}
                >
                    Sort by Time {sortOrder === 'desc' ? '↓' : '↑'}
                </button>
            </div>

            <table className="table-auto w-full">
                <thead>
                    <tr>
                        <th className="px-4 py-2 min-w-[6rem]">Name</th>
                        <th className="px-4 py-2 min-w-[6rem]">Message</th>
                        <th className="px-4 py-2 min-w-[6rem]">State</th>
                        <th className="px-4 py-2 min-w-[6rem]">WhatsApp</th>
                        <th className="px-4 py-2 min-w-[10rem]">Reason</th>
                        <th className="px-4 py-2 min-w-[6rem]">Timestamp</th>
                    </tr>
                </thead>
                <tbody>
                {filteredData.map((data) => (
                        <tr key={data.id}>
                            <td className="border px-4 py-2 min-w-[6rem]">{data.name}</td>
                            <td className="border px-4 py-2 min-w-[6rem]">{data.message}</td>
                            <td className="border px-4 py-2 min-w-[6rem]">{data.state}</td>
                            <td className="border px-4 py-2 min-w-[6rem]">{data.whatsapp}</td>
                            <td className="border px-4 py-2 min-w-[10rem]">{data.reason}</td>
                            <td className="border px-4 py-2 min-w-[6rem]">
                                {new Date(data.timestamp.seconds * 1000).toLocaleDateString('en-GB', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric'
                                })} {new Date(data.timestamp.seconds * 1000).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default FormDataPage;